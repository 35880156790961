import { ToHTML, ToHTMLWithFormatting, GetNonImagefromBlocks, GetImageURLfromBlocks } from "../js/base";
import { async } from "regenerator-runtime";
import { req } from "../js/config";
import Shuffle from 'shufflejs';

let PageData = ''

const Initialize = async () => {
  //getPageData
  await getPageData();
  console.log("🚀 ~ file: main.js ~ line 26 ~ Initialize ~ PageData", PageData)
  await generatePageHTML();
}



const getPageData = async () => {

  console.log('getPageData from the server');
  PageData = await req(`Sections/schoolIndex`).catch(function (response) {
  });
  var t = await req(`Sections/course`).catch(function (response) {
  });
  t.data.forEach(e => { PageData.data.push(e) });
  return PageData;
}

const renderWhyXynaSection_Tpl = async (d) => {
  console.log("🚀 ~ file: main.js ~ line 26 ~ constrenderWhyXynaSection_Tpl= ~ d", d)
  let tpl = `           <div class="col-xl-3 col-sm-6 mb-4 mb-xl-0 text-center">
  <div class="img-zoom rounded-circle d-inline-block">
  <img class="img-fluid rounded-circle" src="${GetImageURLfromBlocks(d[0].body1)}" alt="">
  
  </div>
  <h6 class="my-4">${d[0].title}</h6>
  <p class="mb-4">${GetNonImagefromBlocks(d[0].body1)}</p>
 
</div>
<div class="col-xl-3 col-sm-6 mb-4 mb-xl-0 text-center">
  <div class="img-zoom rounded-circle d-inline-block">
  <img class="img-fluid rounded-circle" src="${GetImageURLfromBlocks(d[1].body1)}" alt="">
  </div>
  <h6 class="my-4">${d[1].title}</h6>
  <p class="mb-4">${GetNonImagefromBlocks(d[1].body1)}</p>
</div>
<div class="col-xl-3 col-sm-6 text-center mb-4 mb-sm-0">
  <div class="img-zoom rounded-circle d-inline-block">
  <img class="img-fluid rounded-circle" src="${GetImageURLfromBlocks(d[2].body1)}" alt="">
  </div>
  <h6 class="my-4">${d[2].title}</h6>
  <p class="mb-4">${GetNonImagefromBlocks(d[2].body1)}</p>
</div>
<div class="col-xl-3 col-sm-6 text-center">
  <div class="img-zoom rounded-circle d-inline-block">
  <img class="img-fluid rounded-circle" src="${GetImageURLfromBlocks(d[3].body1)}" alt="">
  </div>
  <h6 class="my-4">${d[3].title}</h6>
  <p class="mb-4">${GetNonImagefromBlocks(d[3].body1)}</p>
</div>
              `

  document.querySelector('#pageschoolindexwhyxyna').innerHTML = tpl;

}
const CourseList_Tpl = (d) => {
  console.log("🚀 ~ file: main.js ~ line 23 ~ d", d)
  let divs = '';
  d.forEach((c) => {
    divs += `
    
    <div class="grid-item senior" data-groups='["${c.title.split(',')[0]}","${c.title.split(',')[1]}"]'>
    <div class="course">
      <div class="course-img">
        <img class="img-fluid" src="../images/Courses.png" alt="">
        <div class="text-block">
        <h3 >${c.header}</h3>
       
      </div>
        <a href="#" class="course-category"><i class="far fa-bookmark"></i> ${c.title.split(',')[3]}</a>
      </div>
      <div class="course-info">
        <div class="course-title">
          <a href="./coursedetail/${c.title.split(',')[0]}-${c.title.split(',')[3]}-${c.header}">${c.header}</a>
        </div>
        <div class="course-instructor">
          <a href="#">${c.title.split(',')[0]}</a>
        </div>
        <div class="course-rate-price">
          <div class="rating">
            <span> <a href="#">${c.title.split(',')[1]}</a></span>
          </div>
          <div class="price">${c.title.split(',')[2]}</div>
        </div>
      </div>
    </div>
  </div>
 
    `

  });
  return divs;
}


const renderCourseListSection_Tpl = async (d, CourseListTitle) => {
  console.log("🚀 ~ file: main.js ~ line 107 course list ~ d", d, CourseListTitle)
  let tpl = `
                
      
                          
  <section >
  <div class="container">
    <div class="section-title text-center">
      <h2>${CourseListTitle[0].header}</h2>
      <p>${CourseListTitle[0].title}</p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="filters-group mb-2 mb-4">
          <button class="btn-filter active" data-group="all">All</button>
          <button class="btn-filter" data-group="Grade-9">Grade 9</button>
          <button class="btn-filter" data-group="Grade-10">Grade 10</button>
          <button class="btn-filter" data-group="Grade-11">Grade 11</button>
          <button class="btn-filter" data-group="Grade-12">Grade 12</button>
            
        </div>
        <div class="my-shuffle-container grid-4" id="my-shuffle-container">                 
            ${CourseList_Tpl(d)}
          </div>
        </div>
      </div>
    </div>
  </section>  `

  document.querySelector('#pageschoolindexcourselist').innerHTML = tpl;

}

const TestimonialSection_Tpl = async (d) => {
  let divs = '';

  d.forEach(e => {

    divs += `

                <div class="item">
                <div class="testimonial-style-03">
                  <div class="testimonial-info">
                    <div class="testimonial-quote">
                      <i class="flaticon-quote"></i>
                    </div>
                    <div class="testimonial-content">
                    ${GetNonImagefromBlocks(e.body1)}
                    </div>
                    <div class="testimonial-author">
                      <div class="avatar avatar-md">
                        <img class="img-fluid " src="${GetImageURLfromBlocks(e.body1)}" alt="">
                      </div>
                      <div class="testimonial-name">
                        <h6>${e.title}</h6>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>`
  })


  return divs;
}
const renderTestimonialSection_Tpl = async (d) => {
  let tpl = `
  ${await TestimonialSection_Tpl(d)}  
           `

  document.querySelector('#pageschoolindextestimonials').innerHTML = tpl;

}
const renderBannerDataSection_Tpl = async (d) => {
  let tpl = `
  <div class="row h-100">
  <div class="col-md-7 col-lg-6 offset-xl-1 col-xl-4 align-self-center position-relative z-index-1">
    <div class="content" >
  <h2 class="animated text-white mb-4" data-swiper-animation="fadeInUp" data-duration="2.0s" data-delay="1.0s">${d[0].title}</h2>
  <h6 class="text-white" data-swiper-animation="fadeInUp" data-duration="2.0s" data-delay="1.0s">${GetNonImagefromBlocks(d[0].body1)}</h6>
  <form class="form-inline" data-swiper-animation="fadeInUp" data-duration="2.0s" data-delay="1.0s">
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Search Courses...">
    </div>
    <button type="submit" class="btn btn-dark"><i class="fa fa-search"></i></button>
  </form>
  </div>
  </div>
</div>
<figure>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 840">
    <defs>
    <path class="cls-1" id="mainHeroSVG1" d="M36.805,840C16.48,840,0,825.061,0,806.629s16.479-33.37,36.8-33.37l124.835,0c29.458,0,53.349-21.654,53.349-48.371S191.1,676.512,161.64,676.512H93.476c-20.321,0-36.8-14.937-36.8-33.369s16.479-33.37,36.8-33.37h97.312a41.057,41.057,0,0,1,4.364.256H270.4c22.78,0,41.253-16.744,41.253-37.4s-18.473-37.4-41.253-37.4H154.624c-20.321,0-36.8-14.937-36.8-33.368s16.479-33.37,36.8-33.37H185.53V468.4h88.327c20.321,0,36.8-14.936,36.8-33.365s-16.474-33.364-36.79-33.37H255.123c-20.321,0-36.8-14.937-36.8-33.369s16.479-33.368,36.8-33.368l11.675,0.059c41.126,0,74.478-30.231,74.478-67.531s-33.352-67.532-74.478-67.532H198.4c-20.326,0-36.805-14.937-36.805-33.37s16.479-33.367,36.805-33.367h92.312c18.162-2.043,32.238-16.059,32.238-33.085,0-18.432-16.484-33.369-36.805-33.369H253.01c-16.808-3.081-29.468-17.214-29.468-33.369S236.2,3.083,253.01,0H1562V840H36.805Z"/>
    </defs>
    <clipPath id="mainHeroSVG2">
    <use xlink:href="#mainHeroSVG1"></use>
    </clipPath>
    <g transform="matrix(1 0 0 1 0 0)" clip-path="url(#mainHeroSVG2)">
      <image width="1100" height="840" xlink:href="${GetImageURLfromBlocks(d[0].body1)}" transform="matrix(1 0 0 1 0 0)"></image>
    </g>
    <defs>
    <style>
    .cls-1 {
    fill: #1f1f1f;
    fill-rule: evenodd;
    }
    </style>
    </defs>
  </svg>
</figure>
           `

  document.querySelector('#pageschoolindexbannerdata').innerHTML = tpl;

}

const generatePageHTML = async () => {
  //render all sections here
  await renderWhyXynaSection_Tpl(PageData.data.filter(f => f.pageId == 'schoolIndex-WhyXyna'));
  await renderCourseListSection_Tpl(PageData.data.filter(f => f.pageId.includes('Header')), PageData.data.filter(f => f.pageId.includes('schoolIndex-CourseListTitle')));
  await renderTestimonialSection_Tpl(PageData.data.filter(f => f.pageId == 'schoolIndex-Testimonials'));
  await renderBannerDataSection_Tpl(PageData.data.filter(f => f.pageId == 'schoolIndex-BannerData'));

  const shuffleInstance = new Shuffle(document.getElementById('my-shuffle-container'), {
    itemSelector: '.grid-item',
    sizer: '.my-sizer-element',
    speed: 700,
    columnThreshold: 0
  });


  jQuery(".btn-filter").on('click', function () {
    var data_group = jQuery(this).attr('data-group');
    if (data_group != 'all') {
      shuffleInstance.filter([data_group]);
    } else {
      shuffleInstance.filter();
    }
  });
  $(".filters-group .btn-filter").on('click', function () {
    $(".filters-group .btn-filter").removeClass("active");
    $(this).addClass("active");
  });

  var owlslider = jQuery("div.owl-carousel");
  if (owlslider.length > 0) {
    owlslider.each(function () {
      var $this = $(this),
        $items = ($this.data('items')) ? $this.data('items') : 1,
        $loop = ($this.attr('data-loop')) ? $this.data('loop') : true,
        $navdots = ($this.data('nav-dots')) ? $this.data('nav-dots') : false,
        $navarrow = ($this.data('nav-arrow')) ? $this.data('nav-arrow') : false,
        $autoplay = ($this.attr('data-autoplay')) ? $this.data('autoplay') : true,
        $autospeed = ($this.attr('data-autospeed')) ? $this.data('autospeed') : 5000,
        $smartspeed = ($this.attr('data-smartspeed')) ? $this.data('smartspeed') : 1000,
        $autohgt = ($this.data('autoheight')) ? $this.data('autoheight') : false,
        $space = ($this.attr('data-space')) ? $this.data('space') : 30,
        $animateOut = ($this.attr('data-animateOut')) ? $this.data('animateOut') : false;

      $(this).owlCarousel({
        loop: $loop,
        items: $items,
        responsive: {
          0: {
            items: $this.data('xx-items') ? $this.data('xx-items') : 1
          },
          480: {
            items: $this.data('xs-items') ? $this.data('xs-items') : 1
          },
          768: {
            items: $this.data('sm-items') ? $this.data('sm-items') : 2
          },
          980: {
            items: $this.data('md-items') ? $this.data('md-items') : 3
          },
          1200: {
            items: $items
          }
        },
        dots: $navdots,
        autoplayTimeout: $autospeed,
        smartSpeed: $smartspeed,
        autoHeight: $autohgt,
        margin: $space,
        nav: $navarrow,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
        autoplay: $autoplay,
        autoplayHoverPause: true
      });
    });
  }

}

Initialize();

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOMContentLoaded', 'page-index')

})



